import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Spinner from './components/Spinner';
import React from 'react';

const HomeComponent = lazy(() =>
  import(/* webpackChunkName: "home" */ './pages/Home')
);

const ProductComponent = lazy(() =>
  import(/* webpackChunkName: "product" */ './pages/Product')
);

const BlogComponent = lazy(() =>
  import(/* webpackChunkName: "blog" */ './pages/Blog')
);

const ContactComponent = lazy(() =>
  import(/* webpackChunkName: "contact" */ './pages/Contact')
);

const BuildPoolComponent = lazy(() =>
  import(/* webpackChunkName: "pool" */ './pages/pool')
);

const PoolCalculator = lazy(() =>
  import(/* webpackChunkName: "pool" */ './pages/pool/PoolCalculator')
);

const BalancingTank = lazy(() =>
  import(/* webpackChunkName: "pool" */ './pages/pool/DesignBalancingTank')
);

const DynamicShape = lazy(() =>
  import(/* webpackChunkName: "pool" */ './pages/pool/OpenCVPoolCalculator')
);

const LogComponent = lazy(() =>
  import(/* webpackChunkName: "pool" */ './pages/pool/Log')
);

const DiagramComponent = lazy(() =>
  import(/* webpackChunkName: "pool" */ './pages/pool/PoolDiagram')
);

const EstimateCeramicComponent = lazy(() =>
  import(/* webpackChunkName: "pool" */ './pages/pool/EstimateCeramic')
);

function App() {
  return (
    <div className="overflow-hidden no-scrollbar">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Spinner />}>
              <HomeComponent />
            </Suspense>
          }
        />
        <Route
          path="/product"
          element={
            <Suspense fallback={<Spinner />}>
              <ProductComponent />
            </Suspense>
          }
        />
        <Route
          path="/blog"
          element={
            <Suspense fallback={<Spinner />}>
              <BlogComponent />
            </Suspense>
          }
        />
        <Route
          path="/pool"
          element={
            <Suspense fallback={<Spinner />}>
              <BuildPoolComponent />
            </Suspense>
          }
        >
          <Route
            path="calculator"
            element={
              <Suspense fallback={<Spinner />}>
                <PoolCalculator />
              </Suspense>
            }
          />
          <Route
            path="ceramic"
            element={
              <Suspense fallback={<Spinner />}>
                <EstimateCeramicComponent />
              </Suspense>
            }
          />
          <Route
            path="balancing-tank"
            element={
              <Suspense fallback={<Spinner />}>
                <BalancingTank />
              </Suspense>
            }
          />
          <Route
            path="dynamic-shape"
            element={
              <Suspense fallback={<Spinner />}>
                <DynamicShape />
              </Suspense>
            }
          />
          <Route
            path="log"
            element={
              <Suspense fallback={<Spinner />}>
                <LogComponent />
              </Suspense>
            }
          />
          <Route
            path="diagram"
            element={
              <Suspense fallback={<Spinner />}>
                <DiagramComponent />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/contact"
          element={
            <Suspense fallback={<Spinner />}>
              <ContactComponent />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
